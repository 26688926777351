<template>
  <section id="card-content-types">
    <b-row>
      <b-col
        md="6"
        lg="4"
        v-for="(item,index) in list"
        :key="index"
      >
        <a :href="item.url" target="_blank">
          <b-card
            :img-src="item.img"
            img-alt="Card image"
            img-top
          >
            <b-card-body>
              <b-card-title>{{ item.title }}</b-card-title>
              <b-card-text>
                {{ item.text }}
              </b-card-text>
            </b-card-body>
          </b-card>
        </a>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BCardText, BLink, BCardTitle, BCardBody,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BCardTitle,
    BCardBody,
  },
  data() {
    return {
      list: [
        {
          url: 'https://www.examfx.com/',
          img: require('@/assets/images/cards/01.jpg'),
          title: 'Pre-licensing ExamFX',
          text: 'PSI is a provider of online insurance license exams. On the PSI website, you can schedule the time for your insurance license exam and take the exam.'
        },
        {
          url: 'https://home.psiexams.com/#/home',
          img: require('@/assets/images/cards/02.jpg'),
          title: 'Insurance License Exam - PSI',
          text: 'PSI is a provider of online insurance license exams. On the PSI website, you can schedule the time for your insurance license exam and take the exam.'
        },
        {
          url: 'https://www.sircon.com/',
          img: require('@/assets/images/cards/03.jpg'),
          title: 'License Application - Sircon',
          text: 'After you pass the exam, you will need to register for your license. We will use the website Sircon to register your license with the government.'
        },
        {
          url: 'https://www.napa-benefits.org/insurance/errors-and-omissions-eando-insurance',
          img: require('@/assets/images/cards/04.jpg'),
          title: 'Errors and Omissions (E&O) Insurance',
          text: 'Before entering into a contract with an insurance company, it is necessary for you to purchase Errors and Omissions Insurance. This is an insurance specifically designed for brokers, with the aim of protecting your business from losses resulting from negligence, errors, or omissions that lead to claims for damages by others. Our recommended company is NAPA, but you can also choose other companies.'
        },
        {
          url: 'https://www.webce.com/',
          img: require('@/assets/images/cards/05.jpg'),
          title: 'WebCE - AML Training',
          text: 'Before accepting commissions, it is necessary to complete an anti-money laundering course. This is a 4-hour online course. We recommend the online course from WebCE. After completing the online course, you only need to pass a 15-question quiz to obtain an AML certificate.'
        },
        {
          url: 'https://accounts.surancebay.com/oauth/authorize?redirect_uri=https:%2F%2Fsurelc.surancebay.com%2Fproducer%2Foauth%3FreturnUrl%3D%252Fprofile%253FgaId%253D381%2526gaId%253D381%2526branch%253DOne%252520Up%252520Insurance%252520Agency%2526branchVisible%253Dtrue%2526branchEditable%253Dfalse%2526branchRequire%253Dtrue%2526dba%253DP%2526autoAdd%253Dfalse%2526bcc%253Dcontact%2540oneupinsurance.onmicrosoft.com%2526requestMethod%253DGET&gaId=381&client_id=surecrmweb&response_type=code',
          img: require('@/assets/images/cards/06.jpg'),
          title: 'Surancebay - Get Contract with the insurance company',
          text: 'The final step is to sign a contract with the insurance company, and Surancebay is a website that the insurance company uses to contract with agents. You must register an account to sign a contract with the insurance company and manage it.'
        },
      ]
    }
  },
  mounted() {
  }
}
</script>

<style scoped>
</style>
